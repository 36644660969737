/* src/components/ShimmerImage.css */

.shimmer-image-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .shimmer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      #f6f7f8 0%,
      #edeef1 20%,
      #f6f7f8 40%,
      #f6f7f8 100%
    );
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
  }
  
  @keyframes shimmer {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
  
  .shimmer-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
   