.dropzone {
  text-align: center;
  padding: 30px;
  border: 3px dashed #eeeeee;
  color: #bdbdbd;
  cursor: pointer;
  margin-bottom: 20px;
}

.selected-file-wrapper {
  text-align: center;
  margin-top: -100px !important;
}

.selected-file {
  color: #000;
  font-weight: bold;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.minheight { min-height: 200px !important;}