



.form .custom-z-index{   
      z-index: 100;

}

.form span{

        position: absolute;
    right: 17px;
    top: 13px;
    padding: 2px;
    border-left: 1px solid #d1d5db;

}

.left-pan{
    padding-left: 7px;
}

.left-pan i{
   
   padding-left: 10px;
}

.form-input{
    height: 55px;
    text-indent: 33px;
   
    z-index: 50;
}

.form-input:focus{

    box-shadow: none;
    border:none;
}