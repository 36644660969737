/* Import Modern Font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

/* Root Variables for Colors and Fonts */
:root {
  --primary-color: #1A202C; /* Dark gray for modern look */
  --secondary-color: #000000;
  --accent-color: #2D3748; /* Accent dark color */
  --font-family: 'Inter', sans-serif;
  --transition-speed: 0.2s;
  --text-color: #1A202C;
  --bg-color: #000; /* Light gray background */
  --icon-color: #4A5568;
  --icon-hover-color: var(--primary-color);
}

/* Reset default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Navbar Container */
.navbar_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--secondary-color);
  padding: 0 1.5rem;
  border-bottom: 1px solid #E2E8F0;
  height: 60px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

/* Navbar Logo */
.navbar_logo {
  font-weight: 700;
  font-size: 1.5rem;
  font-family: 'Inter', sans-serif;
  color: var(--primary-color);
  text-decoration: none;
}

/* Navbar Items */
.navbar-nav {
  display: flex;
  align-items: center;
  list-style: none;
  gap: 1rem;
}

/* Icon Styles */
.nav-link {
  color: var(--icon-color);
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.nav-link img {
  width: 20px;
  height: 20px;
}

.nav-link span {
  margin-left: 0.5rem;
  font-size: 0.9rem;
  color: var(--text-color);
}

/* Profile Square */
.profile-square {
  background-color: #CBD5E0;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
  font-weight: 600;
  font-size: 16px;
  font-family: var(--font-family);
  text-transform: uppercase;
}

/* Dropdown Styles */
.dropdown {
  position: relative;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--secondary-color);
  min-width: 220px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  border: 1px solid #E2E8F0;
  overflow: hidden;
  z-index: 1000;
}

.dropdown-content a {
  padding: 0.75rem 1rem;
  display: block;
  color: var(--text-color);
  text-decoration: none;
  font-size: 0.9rem;
  transition: background var(--transition-speed);
}

.dropdown-content a:hover {
  background-color: #EDF2F7;
}

/* Button Styles */
.button {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  font-family: var(--font-family);
  cursor: pointer;
  transition: background-color var(--transition-speed);
}

.button:hover {
  background-color: #2D3748;
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar_logo {
    font-size: 1.25rem;
  }

  .navbar-nav {
    gap: 0.75rem;
  }

  .nav-link span {
    display: none;
  }
}

@media (max-width: 576px) {
  .navbar_container {
    padding: 0 1rem;
  }

  .navbar-nav {
    gap: 0.5rem;
  }
}

/* General Styles */
body {
  font-family: var(--font-family);
  background-color: var(--bg-color);
}

/* Tooltip Styles for Notifications */
.tooltip {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #E53E3E;
  color: #000000;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Adjustments for Rectangular Shapes */
.nav-link {
  padding: 0.5rem;
}

.nav-link:hover {
  background-color: #EDF2F7;
  border-radius: 4px;
}
