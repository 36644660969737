/* CustomGoogleButton.css */
.custom-google-button {
    background-color: #333 !important; /* Dark background */
    color: #fff !important; /* White text */
    border: none !important;
    padding: 10px 20px !important;
    border-radius: 4px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  /* Adjust hover state */
  .custom-google-button:hover {
    background-color: #444 !important;
  }
  
  /* If the button includes an image/icon, adjust its appearance */
  .custom-google-button img {
    filter: brightness(0) invert(1); /* Invert logo color for dark background */
  }
  