.timeline-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
 
  padding: 20px;
  border-radius: 8px;
}

.timeline-bar {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 500px;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
}




.timeline-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  
}

.timeline-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
 background-color: #777984;
  border: 2px solid #777984;
  z-index: 1;
}

.timeline-item.active .timeline-circle {
  background-color: #6546FB;
  border-color: #6546FB;
}

.timeline-label {
  margin-top: 5px;
  font-size: 12px;
  color: #777984;
}

.timeline-item.active .timeline-label {
  color: #6546FB;
}

.timeline-line {
  position: absolute;
  top: 9px;
  left: 7%;
  height: 2px;
  background-color: #6546FB;
  z-index: 1;
  transition: width 0.3s ease-in-out;
}



.black-line {
  position: absolute;
  top: 9px;
 right: 6%;
  width: 440px;
  height: 2px;
  background-color: #777984;
  z-index: 0;
}



/* Small devices (phones) */
@media (max-width: 767px) {
.timeline-bar {
  width: 340px;

}

  .timeline-line {
  left: 9%;
    
  }
  .black-line {
     right: 9%;
  width: 280px;
 
}

 
}

/* Medium devices (tablets) */
@media (min-width: 768px) and (max-width: 1199px) {
    
  .timeline-line {
     /* left: 5%; */
    
  }
}

/* Large devices (desktops) */
@media (min-width: 1200px) {
 .timeline-line {
     /* left: 5%; */
    
  }
}
