/* src/components/Preloader/Preloader.css */

/* Preloader styles */
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  #preloader .loader-text {
    font-family: 'Montserrat', sans-serif;
    font-size: 50px;
    font-weight: bold;
    color: rgb(255, 255, 255);
    animation: blink 1.5s infinite;
  }
  
  @keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.5; }
  }
  
  /* Responsive Preloader Styles */
  @media (max-width: 768px) {
    #preloader .loader-text {
      font-size: 40px;
    }
  }
  
  @media (max-width: 576px) {
    #preloader .loader-text {
      font-size: 30px;
    }
  }
  